export default {
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrícula Camión"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combustible"])},
  "selectFuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de combustible"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
  "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un planning"])},
  "expedition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expedición"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolución"])},
  "return?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Viene con devoluciones?"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conozco y acepto"])},
  "securityProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el protocolo de seguridad"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politica de privacidad"])},
  "andThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y la"])},
  "gasoil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesel"])},
  "gasoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasolina"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eléctrico"])},
  "trailerRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrícula Remolque"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "editDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar conductor"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "nif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIF"])},
  "cmrNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de CMR"])},
  "subCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa subcontratada"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepción"])},
  "carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa Transportista"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
  "trailerEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Si no tiene,dejar en blanco)"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar Centro"])},
  "planningsOfToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plannings de hoy"])},
  "planningsOfTomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plannings de mañana"])},
  "oneDayMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mañana"])},
  "oneDayLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
  "tryAgainContactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor inténtalo de nuevo. Si el problema persiste, contacte con un administrador"])},
  "receptionNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de recepción"])}
}