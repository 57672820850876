import { createStore } from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'
import VuexPersist from 'vuex-persist' 

export default createStore({
    plugins: [
        new VuexPersist({
            storage: window.sessionStorage 
        }).plugin
    ],
    state: {
        authenticated: false,
        processes: [],
        fuels: [],
        showFuel: false,
        vada: '',
        plannings: [],
        isLoading: false,
        cmrs: [],
        returns: [],
        returnsOpened:[],
        role:'',
        receptions:[]
    },
    getters,
    actions,
    mutations,
    modules: {},
})
