import cmrApi from "@/api/CmrApi";
import { checkEmail } from "@/helpers/ValidateEmail"
import { responseErrorText } from "@/helpers/Parser";
import Swal from "sweetalert2";
import i18n from '@/i18n.js'

export const initExpedition = async(context, request) => {
    const checks = checkForm(request);
    if (checks.length) {
        return { status: 400, error: checks }
    }  
    const path = '/api/v1/initExpedition'
    const response = cmrApi.post(path, flowExpedition(request)).then(response => {
        return response
    })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: `Error while creating the shipment`,
                text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
                confirmButtonColor: "#3643BA",
                cancelButtonColor: "#6e7d88"
            })
        });
    return response
}


const flowExpedition = (request) => {
    return {
            vada: request.vada,
            ref_planning: request.destinies.destiny,
            process: {
                reference: request.process
            },
            registration: {
                registration_name: request.registration.registration,
                fuel: {
                    id: request.registration.fuel
                }
            },
            trailer: request.trailer,
            driver: {
                nif: request.driver.nif,
                email: request.driver.nif,
                name: request.driver.name
            },
            sub_carrier: request.subCarrier
        
    }
}

export const createReturn = async(context, request) => {
    const { registration, driver, retur, trailer, protocolo, process } = request
    const checks = checkReturn({ data: { registration, driver, retur, trailer, protocolo, process } })
    if (checks.length) {
        return { status: 400, error: checks }
    }

    const returns = retur.returnsAdded.map(r => {
        return {
            reference: r.reference,
            process: {
                reference: process
            },
            registration: {
                registration_name: registration.registration,
                fuel: {
                    id: request.registration.fuel
                }
            },
            trailer: trailer,
            driver: {
                nif: driver.nif,
                name: driver.name,
                email: driver.email
            }
        }
    })

    const response = await saveReturns({returns } ).then(getResponse => {
        return getResponse
    })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: `Error while creating the return`,
                text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
                confirmButtonColor: "#3643BA",
                cancelButtonColor: "#6e7d88"
            })
        });
  
    return response

}

const saveReturns = async(body) => {
    const path = '/api/v1/return'
    return cmrApi.post(path, body)
}

const checkReturn = ({ data }) => {
    const error = []

    if (data.retur.returnsAdded.length === 0) {
        error.push('Returns are empty')
    }
    if (!data.registration.validate) {
        error.push('Registration is not validate')
    } else {
        if (data.registration.validateFuel && !data.registration.fuel) {
            error.push('please select a fuel')
        }
    }
    if (!data.driver.validate) {
        error.push('Driver is not validate')
    } else {
        if (data.driver.validateNameAndEmail && (!data.driver.name || !data.driver.email)) {
            error.push('Name or email is empty')
        } else if (data.driver.validateNameAndEmail && !checkEmail(data.driver.email)) {
            error.push('Driver email is not valid')
        }
    }
    if (!data.trailer) {
        data.trailer = ""
    }
    if (!data.protocolo) {
        error.push('Please read and accept the security protocolo')
    }
    return error
}

const checkForm = (data) => {

    const error = []
    if (!data.registration.validate) {
        error.push('Registration is not validate')
    } else {
        if (data.registration.validateFuel && !data.registration.fuel) {
            error.push('please select a fuel')
        }
    }
    if (!data.driver.validate) {
        error.push('Driver is not validate')
    } else {
        if (data.driver.validateNameAndEmail && (!data.driver.name || !data.driver.email)) {
            error.push('Name or email is empty')
                //falta comprobar el formato del email
        } else if (data.driver.validateNameAndEmail && !checkEmail(data.driver.email)) {
            error.push('Driver email is not valid')
        }
    }
    if (!data.destinies.destiny) {
        error.push('Please select a destiny')
    }
    if (!data.trailer) {
        data.trailer = ""
    }
    if (!data.protocolo) {
        error.push('Please, read and accept the security protocol')
    }

    if (data.retur && data.dataReturn.returnsAdded.length === 0) {
        
        error.push('Please select a CMR to return')
    }
    return error
}

export const editDriver = async(context, driver) => {
    const checks = checkDriver(driver.driver)
    if (checks.length) {
        Swal.fire({  
            icon: "error",
            title: "Oops...",
            text: checks
        });
        return
    }
    const body = {
        reference: driver.reference,
        driver: {
            nif: driver.driver.nif,
            email: driver.driver.email,
            name: driver.driver.name
        }
    }
    const path = '/api/v1/modifyDriver'
    const { data } = await cmrApi.post(path, { body })
    return data
}

const checkDriver = (driver) => {
    const error = []
    if (!driver.validate) {
        error.push('Driver is not validated')
    } else {
        if (driver.validateNameAndEmail && (!driver.name || !driver.email)) {
            error.push('Name or email is empty')
                //falta comprobar el formato del email
        }
    }
    return error
}

export const isAuthenticated = ({ commit }) => {
    commit('isAuthenticated')
}

export const isAuthenticatedToFalse = ({ commit }) => {
    commit('isAuthenticatedToFalse')
}

export const createReception= async (context, request)=>{
    const path = '/api/v1/reception'
    const response =  cmrApi.post(path, flowReception(request)).then(getResponse => {
        return getResponse
    })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: `Error while creating the recepetion`,
                text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
                confirmButtonColor: "#3643BA",
                cancelButtonColor: "#6e7d88"
            })
        });
  
    return response
    
}

export const flowReception = (request) => {
    const checks = checkReceptionFlow(request);
    if (checks.length) {
        Swal.fire({
            icon: "error",
            title: "Missing data!",
            text:checks,
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        })

        throw new Error('Error, missing data!');
    }  
    return {
            carrier:request.carrier,
            origin:request.origin,
            origin_hours:request.origin_hours,
            created_at:request.created_at,
            vada:request.vada,
            reception_number:request.receptionNumber,
            cmr_process:{
                process: {
                    reference: request.cmrProcess.process.reference
                },
                registration:{
                    registration_name:request.cmrProcess.registration.registration,
                    fuel:{
                        id: request.cmrProcess.registration.fuel
                    }
                },
                trailer: {
                    name:request.cmrProcess.trailer,
                    trailer_states:[{state:false}]
                },
                driver: {
                    email:request.cmrProcess.driver.email,
                    name:request.cmrProcess.driver.name,
                    nif: request.cmrProcess.driver.nif

                }
  

        } 
    }
}

const checkReceptionFlow = (request) => {
    const error = []
    if(request.origin === ''){
        error.push('Please select a origin')    
    }

    if(request.carrier === ''){
        error.push('Please write your transport company')  
    }
   
    return error
}

export const signOut = (context,request) => {
    const path = '/auth/revoke'
    const response =  cmrApi.post(path, request).then(getResponse => {
        return getResponse
    })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: `Error while trying ot login out`,
                text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
                confirmButtonColor: "#3643BA",
                cancelButtonColor: "#6e7d88"
            })
        });
  
    return response
    
}



