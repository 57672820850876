export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue 3 i18n"])},
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel"])},
  "selectFuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a fuel"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a planning"])},
  "expedition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
  "return?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are returns?"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know and accept"])},
  "securityProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the security protocol"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
  "andThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and the"])},
  "gasoil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasoil"])},
  "gasoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasoline"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric"])},
  "trailerRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailer registration"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "editDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Driver"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "nif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification Document"])},
  "cmrNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMR number"])},
  "subCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcontracted carrier"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception"])},
  "carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrier"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
  "trailerEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(If none, leave blank)"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Center"])},
  "planningsOfToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's plannings"])},
  "planningsOfTomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow's plannings"])},
  "oneDayMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])},
  "oneDayLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "tryAgainContactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again. If the problem persists, please contact and administrator"])},
  "receptionNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception number"])}
}