import cmrApi from "@/api/CmrApi"
import planningApi from "@/api/PlanningApi"
import { responseErrorText } from "@/helpers/Parser";
import Swal from "sweetalert2";
import i18n from '@/i18n.js'

export const getProcesses = (state) => async () => {
    const path = `/api/v1/processes`
    const response = await cmrApi.get(path)
    state.processes = [...response.data]
}

export const getRegistration = (state,getters,rootState) => async (registrationName) => {
    const vada = rootState.vada;
    const path = `/api/v1/registration?registrationName=${registrationName}&vada=${vada}`
    const response = await cmrApi.get(path)
    if (!response.data.registered) {
        state.fuels = response.data.fuel
        state.showFuel = true
    }
    return response.data
}

export const getDriver = () => async (driverNif) => {
    const path = `/api/v1/driver?driverNif=${driverNif}`
    const response = await cmrApi.get(path)

    return response.data[0]
}

export const getPlannings = (state) => async (week, day, closing) => {
    state.plannings = []
    if(day == 0){
        day = 7
    }

    
    const path = `/api/v1/planningsByVada?vada=${state.vada}&week=${addZeroToNumWeekIfNeeded(week)}&day=${day}&closing=${closing}`
    const response= await planningApi.get(path)
    if (!response.data) return
    let planningsNotDeleted = []
    response.data.forEach(function(planning){

        if(!planning.planning_destiny.delete){
       planningsNotDeleted.push(planning)

        }
        state.plannings = [...planningsNotDeleted]
        state.isLoading = false
    
    })
}

export const getCmrs = (state) => async () => {

    const path = `/api/v1/load?vada=${state.vada}&closing=false`
    const response= await cmrApi.get(path)

    if (response.status === 200) {
        state.cmrs = [...response.data]
        state.isLoading = false
    } else {
        state.isLoading = false
    }
}

export const getReturns = (state) => async () => {
    const path = `/api/v1/return?delete=false&vada=${state.vada}&lasttwentydays=true`
    const response = await cmrApi.get(path)

    if (response.data) {
        state.returns = [...response.data]
        state.isLoading = false
    } else {
        state.returns = []
        state.isLoading = false
    }
}

export const getReturnsOpened = (state) => async () => {
    const path = `/api/v1/return?vada=${state.vada}&closing=false`
    const response = await cmrApi.get(path)

    if (response.data) {
        state.returnsOpened = [...response.data]
        state.isLoading = false
    } else {
        state.returnsOpened = []
        state.isLoading = false
    }
}

export const getCenters = (state) => async () => {
    try {
        const path = "/api/v1/centers"
        const response = await planningApi.get(path)
        if (response.data) {
            state.centers = [...response.data]
            state.isLoading = false
        } else {
            state.isLoading = false
        }
    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error ${err.response.status}`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        });
    }
}

export const getReceptionsFromApiPlanning = (state) => async () => {
    try {
        const path = `/api/v1/receptions?vada=${state.vada}`
        const response = await planningApi.get(path)
        if (response.data) {
            state.receptions = [...response.data]
            state.isLoading = false
        } else {
            state.isLoading = false
        }
    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error ${err.response.status}`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        });
    }
}

export const addZeroToNumWeekIfNeeded = (week) =>{
    let weekNumber = null
    if (week.length <8){
        weekNumber=   week.substring(0, 6) + 0 + week.substring(6);
    }else{
        weekNumber = week
    }   
    return weekNumber

}