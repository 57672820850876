export default {
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carburante"])},
  "selectFuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione il carburante"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un orario"])},
  "expedition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedizione"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorni"])},
  "return?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dei ritorni?"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conosco e accetto i termini"])},
  "securityProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il protocollo della"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politica sulla privac"])},
  "andThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
  "gasoil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzina"])},
  "gasoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disel"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elettrico"])},
  "trailerRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targa del camion"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare"])},
  "editDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica del conducente"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudere"])},
  "nif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità"])},
  "cmrNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero della CMR"])},
  "subCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcontracted carrier"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricezione"])},
  "carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa Portante"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
  "trailerEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Se non c'è, lasciare in bianco)"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio Magazzino"])},
  "planningsOfToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pianificazione di oggi"])},
  "planningsOfTomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pianificazione di domani"])},
  "oneDayMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domani"])},
  "oneDayLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
  "tryAgainContactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore riprova. Se il problema persiste, contatta un amministratore"])},
  "receptionNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ricezione"])}
}